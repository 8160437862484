<template>
	<div class="FormContainerBlock">
		<div
			:id="'formContainer_' + model.contentId"
			:class="[model.backgroundColor?.cssClass, `p-${model.paddingAll}`, model.bmetricId]"
		></div>
	</div>
</template>

<script setup lang="ts">
	import { onMounted } from 'vue';
	import formRenderingService from '@/episerver/scripts/FormRenderingService';
	import { dataLayer, handleGtmHeader } from '@/components/utilities/trackingUtility';

	const props = defineProps<{
		model: any;
	}>();

	const clickedFields = new Set();

	const renderForm = () => {
		const element = document.getElementById('formContainer_' + props.model.contentId);
		formRenderingService.render(props.model, element);
	};

	// Start tracking when input field is clicked
	const handleInputFieldClick = () => {
		const inputField = document.querySelector('.FormTextbox__Input');
		const startInputTracking = dataLayer && inputField && !clickedFields.has(inputField);

		if (startInputTracking) {
			clickedFields.add(inputField);

			dataLayer.push({
				event: 'trackFormStart',
				formName: handleGtmHeader(props.model.header, 'missing title on form'),
				url: window.location.href,
			});
		}
	};

	let hasSubmitted = false;

	const handleFormSubmit = () => {
		const formStatusMessage = document.querySelector('.Form__Status__Message');
		const startSubmitTracking = dataLayer && formStatusMessage;

		if (startSubmitTracking && !hasSubmitted) {
			dataLayer.push({
				event: 'trackFormSubmit',
				formName: handleGtmHeader(props.model.header, 'missing title on form'),
				url: window.location.href,
			});
			hasSubmitted = true;
		}
	};

	onMounted(() => {
		renderForm();

		const inputFields = document.querySelectorAll('.FormTextbox__Input');

		inputFields.forEach((inputField) => {
			inputField.addEventListener('click', handleInputFieldClick, { once: true });
		});

		const formStatusMessage = document.querySelector('.Form__Status__Message') as HTMLElement;
		const observer = new MutationObserver((mutationsList) => {
			for (const mutation of mutationsList) {
				const targetElement = mutation.target as HTMLElement;
				if (
					mutation.type === 'attributes' &&
					mutation.attributeName === 'class' &&
					targetElement.classList.contains('Form__Success__Message')
				) {
					handleFormSubmit();
					const recaptchaText = document.querySelector('.formcontainerblock__recaptcha') as HTMLElement;
					recaptchaText.style.display = 'none';
					observer.disconnect();
				}
			}
		});

		observer.observe(formStatusMessage, { attributes: true });
	});
</script>

<style lang="scss">
	@import './GlobalFormContainerBlock.module.scss';
</style>
