<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		:class="[
			'teaser-card',
			'd-flex',
			'flex-col',
			model.backgroundColor?.cssClass,
			`pt-${paddingTop()}`,
			`pb-${paddingBottom()}`,
			model.bmetricId,
		]"
	>
		<smart-link
			class="teaser-card__img"
			:style="model.ctaBlock?.link?.url ? 'pointer-events:auto' : 'pointer-events:none'"
			:url="buttonUrl()"
			:target="model.ctaBlock?.target"
			:gtmLabel="model.ctaBlock?.gtmLabel"
			:gtmAction="model.ctaBlock?.gtmAction"
		>
			<lazy-image
				v-if="!storybook"
				:image="model.image"
				:fixedHeight="imgHeight"
				:class="imgHeight !== 'usp' ? 'grow' : ''"
			/>

			<!-- For storybook (storybook cannot render lazy images) -->
			<picture
				v-if="storybook"
				:style="[
					{ 'background-image': `url(${model.image?.url})` },
					imgStyle === 'square' ? 'width:125px;margin:auto;' : '',
				]"
			>
				<img
					class="lazy-image__full"
					:class="imgHeight"
					:style="[imgStyle === 'square' ? '' : imgStyle]"
					style="opacity: 1; object-fit: cover"
					alt=""
					:src="model.image?.url"
				/>
			</picture>
		</smart-link>
		<div
			v-if="model.textArea"
			:class="[
				'teaser-card__body rich-text text-center',
				`mb-3`,
				`px-${paddingSides()}`,
				model.imgHeight === 'usp' ? 'pt-0' : 'pt-3',
			]"
			v-html="model.textArea"
		></div>
		<div
			v-if="model.ctaBlock && model.ctaBlock.title"
			class="teaser-card__cta flex-center mb-2"
		>
			<smart-link
				v-if="model.ctaBlock.link.url"
				class="btn"
				:class="ctaClasses(model.ctaBlock)"
				:url="buttonUrl()"
				:target="model.ctaBlock?.target"
				:gtmLabel="model.ctaBlock?.gtmLabel"
				:gtmAction="model.ctaBlock?.gtmAction"
				:useVueRoute="model.ctaBlock.link.useVueRoute"
				v-text="model.ctaBlock?.title"
			/>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed } from 'vue';
	import { ITeaserCardBlock, ICta } from '@/types/block';

	const props = defineProps<{
		model: ITeaserCardBlock;
		storybook?: boolean;
	}>();

	const buttonUrl = () => {
		return props.model.ctaBlock?.link && props.model.ctaBlock.link.url ? props.model.ctaBlock.link.url : '#';
	};

	const paddingTop = () => {
		return props.model?.paddingTop === 'default' ? '3' : props.model?.paddingTop;
	};

	const paddingSides = () => {
		return props.model?.paddingSides === 'default' ? '3' : props.model?.paddingSides;
	};

	const paddingBottom = () => {
		return props.model?.paddingBottom === 'default' ? '3' : props.model?.paddingBottom;
	};

	const imgHeight = computed(() => {
		if (props.model.imgHeight) {
			const img = props.model.imgHeight;
			switch (img) {
				default:
					return 160;
				case 'sm':
					return 160;
				case 'md':
					return 200;
				case 'lg':
					return 240;
				case 'usp':
					return 'usp';
				case 'sm-usp':
					return 'uspSmall';
			}
		}
		return false;
	});

	function ctaClasses(btn: ICta) {
		const btnClassesArr = [];

		if (btn.btnType) {
			btnClassesArr.push('btn--' + btn.btnType);
		}

		if (btn.btnSize) {
			btnClassesArr.push('btn--' + btn.btnSize);
		}

		if (btn.btnColor) {
			btnClassesArr.push('btn--' + btn.btnColor);
		}

		const btnClasses = btnClassesArr.join(' ');
		return btnClasses;
	}

	// Storybook
	const imgStyle = computed(() => {
		if (fixedHeight.value) {
			if (fixedHeight.value === 'square') {
				return 'width:125px;margin:auto;';
			} else if (fixedHeight.value === 'smallSquare') {
				return 'width:56px;margin:auto;';
			}
			return fixedHeight.value;
		}
		return 'aspect-ratio: 16/9';
	});

	const fixedHeight = computed(() => {
		if (imgHeight.value) {
			if (typeof imgHeight.value === 'number') {
				return 'height:' + imgHeight.value + 'px;';
			}
			if (imgHeight.value === 'usp') {
				return 'square';
			} else if (imgHeight.value === 'uspSmall') {
				return 'smallSquare';
			}
		}
		return '';
	});
</script>

<style lang="scss" scoped>
	@import './TeaserCardBlock.module.scss';
</style>
