<!-- eslint-disable vue/no-v-html -->
<template>
	<div
		:class="[
			'teaser-product-card',
			'd-flex',
			'flex-col',
			model.backgroundColor?.cssClass,
			`pt-${paddingTop()}`,
			`pb-${paddingBottom()}`,
			model.bmetricId,
		]"
	>
		<div class="teaser-product-card__img">
			<lazy-image
				v-if="!storybook"
				:image="model.image"
				:fixedHeight="imgHeight"
				:class="imgHeight !== 'usp' ? 'grow' : ''"
			/>

			<!-- For storybook (storybook cannot render lazy images) -->
			<picture
				v-if="storybook"
				:style="[
					{ 'background-image': `url(${model.image?.url})` },
					imgStyle === 'square' ? 'width:125px;margin:auto;' : '',
				]"
			>
				<img
					class="lazy-image__full"
					:class="imgHeight"
					:style="[imgStyle === 'square' ? '' : imgStyle]"
					style="opacity: 1; object-fit: cover"
					alt=""
					:src="model.image?.url"
				/>
			</picture>
		</div>
		<h2
			v-if="model.title"
			class="text-center mt-2"
			v-text="model.title"
		></h2>
		<div
			v-if="model.textArea"
			:class="[
				'teaser-product-card__body rich-text text-center',
				`mb-3`,
				`px-${paddingSides()}`,
				model.imgHeight === 'usp' ? 'pt-0' : 'pt-3',
			]"
			v-html="model.textArea"
		></div>
		<!--Input-->
		<div
			v-if="model.inputId"
			class="flex-center mb-2"
		>
			<div class="teaser-product-card__input_block">
				<label
					:for="model.inputId + model.title"
					class="teaser-product-card__label"
					>{{ model.inputLabel }}</label
				>
				<br />
				<input
					:id="model.inputId + model.title"
					ref="inputId"
					type="text"
					class="teaser-product-card__input"
					:placeholder="model.inputPlaceholder"
					@input="updateUrl"
					@keyup.enter="goSubmit"
				/>
			</div>
		</div>

		<!--Alternate CTA-->
		<div
			v-if="model.alternateCtaBlock && model.alternateCtaBlock.title"
			class="teaser-product-card__cta__alternate flex-center"
		>
			<smart-link
				v-if="model.alternateCtaBlock.link.url"
				:url="model.alternateCtaBlock.link.url"
				:class="ctaClasses(model.alternateCtaBlock)"
				:target="model.alternateCtaBlock.target"
				:title="model.alternateCtaBlock.title"
				:gtmLabel="model.alternateCtaBlock.gtmLabel"
				:gtmAction="model.alternateCtaBlock.gtmAction"
				:useVueRoute="model.alternateCtaBlock.link?.useVueRoute"
				@click="trackAddToCard"
			>
				{{ model.alternateCtaBlock.title }}
				<smart-svg
					v-if="model.alternateCtaBlock.target === '_blank'"
					class="footer__nav-link-icon"
					:url="IconLinkExternal"
				>
				</smart-svg>
			</smart-link>
		</div>

		<!--Main CTA-->
		<div
			v-if="model.ctaBlock && model.ctaBlock.title"
			class="teaser-product-card__cta flex-center mb-2"
		>
			<smart-link
				v-if="model.ctaBlock.link.url"
				:id="buttonId"
				:url="state.url"
				:class="ctaClasses(model.ctaBlock)"
				:target="model.ctaBlock.target"
				:title="model.ctaBlock.title"
				:gtmLabel="model.ctaBlock.gtmLabel"
				:gtmAction="model.ctaBlock.gtmAction"
				:useVueRoute="model.ctaBlock.link?.useVueRoute"
				@click="trackAddToCard"
			>
				{{ model.ctaBlock.title }}
				<smart-svg
					v-if="model.ctaBlock.target === '_blank'"
					class="footer__nav-link-icon"
					:url="IconLinkExternal"
				>
				</smart-svg>
			</smart-link>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { computed, onMounted, reactive, ref } from 'vue';
	import IconLinkExternal from '@/assets/svg/icon_system_external_link.svg';
	import { ITeaserProductCardBlock, ICta } from '@/types/block';
	import {
		TrackProductItem,
		dataLayer,
		getTrackCategory2,
		getTrackCategory3,
	} from '@/components/utilities/trackingUtility';

	const inputId = ref(null);

	const props = defineProps<{
		model: ITeaserProductCardBlock;
		storybook?: boolean;
	}>();

	const state = reactive({
		url: '',
	});

	onMounted(() => {
		state.url = buttonUrl();
	});

	const buttonId = 'submitBtn' + Math.random() + '';

	const buttonUrl = () => {
		let url = '#';
		if (props.model.ctaBlock?.link?.url) {
			url = props.model.ctaBlock.link.url;
			if (inputId?.value) {
				const input = inputId.value as HTMLInputElement;
				const value = input.value;
				url = `${url}?${props.model.inputId}=${value}`;
			}
		}
		return url;
	};

	const updateUrl = () => {
		state.url = buttonUrl();
	};

	const goSubmit = () => {
		document.getElementById(buttonId)?.click();
	};

	const paddingTop = () => {
		return props.model?.paddingTop === 'default' ? '3' : props.model?.paddingTop;
	};

	const paddingSides = () => {
		return props.model?.paddingSides === 'default' ? '3' : props.model?.paddingSides;
	};

	const paddingBottom = () => {
		return props.model?.paddingBottom === 'default' ? '3' : props.model?.paddingBottom;
	};

	const imgHeight = computed(() => {
		if (props.model.imgHeight) {
			const img = props.model.imgHeight;
			switch (img) {
				default:
					return 160;
				case 'sm':
					return 160;
				case 'md':
					return 200;
				case 'lg':
					return 240;
				case 'usp':
					return 'usp';
				case 'sm-usp':
					return 'uspSmall';
			}
		}
		return false;
	});

	function ctaClasses(btn: ICta) {
		const btnClassesArr: Array<string> = [];

		if (btn.btnType) {
			btnClassesArr.push('btn--' + btn.btnType);
		}

		if (btn.btnSize) {
			btnClassesArr.push('btn--' + btn.btnSize);
		}

		if (btn.btnColor) {
			btnClassesArr.push('btn--' + btn.btnColor);
		}

		const btnClasses = 'btn ' + btnClassesArr.join(' ');
		return btnClasses;
	}

	// Storybook
	const imgStyle = computed(() => {
		if (fixedHeight.value) {
			if (fixedHeight.value === 'square') {
				return 'width:125px;margin:auto;';
			} else if (fixedHeight.value === 'smallSquare') {
				return 'width:56px;margin:auto;';
			}
			return fixedHeight.value;
		}
		return 'aspect-ratio: 16/9';
	});

	const fixedHeight = computed(() => {
		if (imgHeight.value) {
			if (typeof imgHeight.value === 'number') {
				return 'height:' + imgHeight.value + 'px;';
			}
			if (imgHeight.value === 'usp') {
				return 'square';
			} else if (imgHeight.value === 'uspSmall') {
				return 'smallSquare';
			}
		}
		return '';
	});

	const trackAddToCard = (): void => {
		try {
			const productName = props.model.title?.toLocaleLowerCase().trim() || 'NA';
			const items: TrackProductItem[] = [];
			items.push({
				item_id: productName,
				item_name: productName,
				affiliation: 'online',
				coupon: 'one insurance - 15% discount',
				discount: 0,
				item_brand: 'alm. brand',
				item_category: 'insurance',
				item_category2: getTrackCategory2(productName),
				item_category3: getTrackCategory3(productName),
				item_variant: 'basic coverage',
				price: 0,
				quantity: 1,
			});

			dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
			dataLayer.push({
				event: 'add_to_cart',
				ecommerce: {
					currency: 'DKK',
					value: 0,
					items,
				},
			});
		} catch (e) {
			// too bad
			console.error(e);
		}
	};
</script>

<style lang="scss" scoped>
	@import './TeaserProductCardBlock.module.scss';
</style>
